import React, { Component } from "react";
import {
  Dimensions,
  ScrollView,
  StyleSheet,
  Text,
  Alert,
  ActivityIndicator,
  TextInput,
  Platform,
  TouchableOpacity,
  FlatList,
  DatePickerIOS,
  View
} from "react-native";
import { connect } from "react-redux";
import colors from "../constants/colors";
import moment from "moment-timezone";
import FormCard from "./widgets/formCard";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Animations from "./animations"
import alerts from "./components/alert"
//import { Actions } from "react-native-router-flux";
import {
  setFilterSelected,
  setFormSelected,
  setTaskSelected,
  shouldReloadTasks,
  createNewTask,
  getTasks,
  setFormsFromAPI,
  quitOrderForm
} from "../actions/tasks"; 
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import { navigateForm } from "../actions/menu";
import { color } from "react-native-reanimated";
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";
import DropDown from "./widgets/dropdown";
import FloatingMenu from "./widgets/floatingMenu";
import { setPromptTitle } from "../actions/tasks";
import { StackActions } from "@react-navigation/native";
import { branding, isBeta } from "../api";

class NewTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_name: "",
      task_description: "",
      startDate: new Date(),
      expirationDate: new Date(),
      frequencies: [
        {
          text: "Day",
          isSelected: false,
        },
        {
          text: "Week",
          isSelected: false,
        },
        {
          text: "Month",
          isSelected: false,
        },
        {
          text: "Year",
          isSelected: false,
        },
      ],
      formData: this.props.formList,
      formFiltered: [],
      formSelected: {},
      isLoading: false,
      width: window.innerWidth,
      filterOptions: [
        {value: "Standard", filter: 'isStandard'},
        {value: "Recipe", filter: 'isRecipe'},
        // {value: "Ingredient", filter: 'isIngredient'},
        // {value: "Major Equipment", filter: 'isEquipment'},
        // {value: "Packaging Materials", filter: 'isPackaging'}
      ],
      filterValue: {value: "Standard", filter: 'isStandard'},
      searchString: ""
    };
    this.registerNewTask=this.registerNewTask.bind(this);
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  changeButton = (number) => {
    let clone = this.state.frequencies;
    clone.map((item, index) => {
      if (index == number && !item.isSelected) {
        item.isSelected = true;
      } else if (item.isSelected && index != number) {
        item.isSelected = false;
      }
      return item;
    });
    this.setState({
      frequencies: clone,
    });
  };

  filterForms = (search, filterValue) => {
    let clone = [];
    const { route, navigation } = { ...this.props };
    const { category } = { ...route.params };
    const preFilteredFomrs = this.state.formData.filter( form => form?.promptMessages?.category == category );
    clone = preFilteredFomrs.filter((item) => {
      if(this.props.showTypesFilter){
        return (item.title.toLowerCase().includes(search.toLowerCase()) && item[filterValue.filter]);
      }else{
        return item.title.toLowerCase().includes(search.toLowerCase());
      }
    });
    this.setState({ formFiltered: clone });
    if(preFilteredFomrs.length === 1){
      if(typeof preFilteredFomrs[0]?.promptMessages != 'undefined'){
        if(!(preFilteredFomrs[0]?.promptMessages?.visible == false)){
          this.props.setPromptTitle(preFilteredFomrs[0].name);
          setTimeout(()=>{
            navigation.dispatch(StackActions.push('PromptFormView', { promptMessages: preFilteredFomrs[0]?.promptMessages, form: preFilteredFomrs[0] }));
          }, 200);
        }
      }
    }
  };

  selectForm = (number) => {
    let clone = JSON.parse(JSON.stringify(this.state.formFiltered));
    
    clone.map((item, index) => {
      if (index == number && item.isSelected==false) {
        item.isSelected = true;
      } else if (item.isSelected==true && index != number) {
        item.isSelected = false;
      }
      return item;
    });
    this.setState({
      formFiltered: clone,
    });

  };

  componentDidMount() {
    if (!this.props.formsLoaded) {
      this.setState({
        isLoading: true,
      });
      this.props.setFormsFromAPI();
    }else {
      this.setState(
        {
          formData: this.props.formList,
          isLoading: false,
        },
        () => {
          this.filterForms(this.state.searchString , this.state.filterValue);
        }
      );
    }
    window.addEventListener('resize', this.handleResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.route.params?.category != prevProps.route.params?.category) {
      this.filterForms(this.state.searchString , this.state.filterValue);
    }
    if (prevProps.formList !== this.props.formList){
      this.setState(
        {
          formData: this.props.formList,
          isLoading: false,
        },
        () => {
          this.filterForms(this.state.searchString , this.state.filterValue);
        }
      );
    }
    if(prevProps.taskCreated !== this.props.taskCreated){
      this.props.selectForm(
        this.state.formSelected._id,
        this.state.formSelected.title,
        this.props.previousSubmissions,
        this.props.taskCreated._id,
        this.state.formSelected?.promptMessages ? this.state.formSelected?.promptMessages : null,
        this.state.formSelected?.isPallet ? this.state.formSelected?.isPallet : null, 
        this.state.formSelected?.isReceivingLog ? this.state.formSelected?.isReceivingLog : null,
        this.state.formSelected?.isOrder ? this.state.formSelected?.isOrder : null,
        this.state.formSelected?.isRecipe ? this.state.formSelected?.isRecipe : null,
        this.state.formSelected?.defaultYield ? this.state.formSelected?.defaultYield : null,
        this.state.formSelected?.isInvoice ? this.state.formSelected?.isInvoice : null,
        this.state.formSelected?.isShipmentLog ? this.state.formSelected?.isShipmentLog : null,
        this.state.formSelected?.isReconciliationLog ? this.state.formSelected?.isReconciliationLog : null,
        this.state.formSelected?.isInventoryTransfer ? this.state.formSelected?.isInventoryTransfer : null,
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  registerNewTask = () => {
    const { formSelected } = this.state;
    if (!formSelected.title) {
      Swal.fire({
        title: "Please select a form",
        text: "You need to select a form in order to proceed.",
        confirmButtonText: "OK",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
      return;
    }
    let start = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");
    let object = {
      name: formSelected.title,
      description: formSelected.title,
      forms_id: formSelected._id,
      start_date: start,
      expiration_date: moment(start).add(1, "day").format("YYYY-MM-DD HH:mm"),
      repeat_frequency: "",
      status: "archive",
      newRecord: true
    };
    this.props.createNewTask(object);
  };

  renderButtons = () => {
    let buttons = [];
    this.state.frequencies.forEach((item, index) => {
      buttons.push(
        <TouchableOpacity
          onPress={() => this.changeButton(index)}
          key={index + ""}
          style={[
            styles.button,
            {
              backgroundColor: item.isSelected
                ? colors.primary
                : colors.gray_darken_2,
            },
          ]}
        >
          <Text style={styles.buttonText}>{item.text}</Text>
        </TouchableOpacity>
      );
    });
    return buttons;
  };

  renderForms = () => {
    let formCards = [];
    this.state.formFiltered.forEach((item, index) => {
      formCards.push(
        <FormCard
          action={() => {
            this.selectForm(index);
            this.setState({
              formSelected: item,
            });
          }}
          key={index + ""}
          formTitle={item.title}
          formSections={item.sections.length}
          formDescription={item.description}
          formImageUri={item.image}
          isSelected={item.isSelected}
        />
      );
    });
    return formCards;
  };

  render() {
    const { filterOptions, filterValue, searchString } = this.state;
    return (
      <View style={{
        flexDirection: "row"
      }}>
        {
          (branding == "iqops" || isBeta == "true") &&
          <FloatingMenu />
        }
        <View style={{ flex: 1 }}/>
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
          <View style={{ display: "flex", justifyContent: "center" }}>
            <Text style={{
              paddingLeft: 10,
              textAlign: "center",
              fontSize: 22,
              fontFamily: "Roboto-Medium",
              marginTop: 20,
              marginBottom: 10,
              color: colors.title

            }}>Add a new report</Text>
            <Text style={{
              color: colors.tweny_black,
              textAlign: "center",
              fontSize: 16,
              paddingLeft: 10,
              paddingBottom: 10,
              marginTop: 10,
              fontFamily: "Roboto",
            }}>
              Select the form you want to create your report from.
            </Text>
          </View>
          <View style={{
              height: 40,
              borderRadius: 20,
              flexDirection: "row",
              // backgroundColor: colors.gray_darken_2,
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10
          }}>
              <MaterialCommunityIcons
                style={{paddingLeft: 40}}
                name="magnify"
                size={30}
                color={colors.search_bar}
              />
              <TextInput
                style={
                  {
                    borderRadius: 20,
                    flex: 1,
                    maxWidth: 450,
                    height: 30,
                    paddingTop: 10,
                    paddingRight: 10,
                    paddingBottom: 10,
                    paddingLeft: 20,
                    fontSize: 18,
                    color: colors.search_bar,
                  }
                }
                value={searchString}
                placeholder="Search for a form"
                onChangeText={(search) => {
                  this.setState({ searchString: search }, ()=> this.filterForms(search, filterValue))
                }}
                underlineColorAndroid="transparent"
              />
            </View>
            {/* {
              this.props.showTypesFilter && 
                <View style={{
                  height: 'auto',
                  borderRadius: 20,
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: 40,
                  paddingRight: 40
                }}>
                  <Text
                    style={{
                      color: colors.tweny_black,
                      fontSize: 16,
                      fontFamily: 'Roboto'
                    }}
                  >
                    Search by type
                  </Text>
                </View>
            } */}
            {
              this.props.showTypesFilter && 
                <View style={{
                  height: 'auto',
                  borderRadius: 20,
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  width: "100%"
                }}>
                  <View style={{
                  height: 'auto',
                  borderRadius: 5,
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  border: 'solid 1px',
                  borderColor: colors.tweny_black
                }}>
                  <TouchableOpacity
                   style={{...styles.btnFilter, backgroundColor: filterValue.value == "Standard" ? colors.filterByType : "#F8F8F8", }}
                   onPress={()=> this.setState({ filterValue: filterOptions[0] }, ()=> this.filterForms(searchString, filterOptions[0]))}>
                    <Text>
                      Standard
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={{...styles.btnFilter, backgroundColor: filterValue.value == "Recipe" ? colors.filterByType : "#F8F8F8", }}
                    onPress={()=> this.setState({ filterValue: filterOptions[1] }, ()=> this.filterForms(searchString, filterOptions[1]))}
                    >
                    <Text>
                       Recipe
                    </Text>
                  </TouchableOpacity>
                </View>
                  
                </View>
            }
            {
              this.props.formsLoaded ?
              <TouchableOpacity 
                onPress={() => {
                  this.setState({
                    isLoading: true,
                  });
                  this.props.setFormsFromAPI();
                }}
                style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: 30 }}>
                <Text style={{
                  fontFamily: "Roboto-Bold",
                  fontSize: 12,
                  color: colors.subtitle,
                }}>Reload Forms</Text>
                <MaterialCommunityIcons
                  name="refresh"
                  style={{
                    fontSize: 15,
                    paddingHorizontal: 10,
                    color: "gray",
                  }}
                />
              </TouchableOpacity> : 
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: 10 }}/>
                <Text style={styles.txtLoading}>Loading forms, please wait.</Text>
              </View>
            }
          <View style={{ backgroundColor: colors.formsContainer, margin: 10, borderRadius: 20 }}>
                <FlatList
                  data={this.state.formFiltered}
                  style={{ marginBottom: 20 }}
                  renderItem={({ item, index }) => (
                    
                      !(item?.promptMessages?.visible == false) ? 
                        <FormCard
                          action={() => {
                            if(item?.promptMessages?.category){
                              this.props.setPromptTitle(item.name);
                              setTimeout(()=>{
                                this.props.navigation.dispatch(StackActions.push('PromptFormView', { promptMessages: item?.promptMessages, form: item}));
                              },200)
                              return;
                            }
                            this.selectForm(index);
                            this.setState({
                              formSelected: item,
                            }, () => { 
                              this.registerNewTask(); 
                            });
                          }}
                          key={index + ""}
                          formTitle={item.title}
                          // formSections={item.sections.length}
                          formDescription={item.description}
                          formImageUri={item.image}
                          isSelected={item.isSelected}
                        /> 
                      : null
                    
                    
                  )}
                  keyExtractor={(item) => item.id}
                />
                {
                  (!this.props.formsLoadedEnd && this.props.formsLoaded) &&
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: 10 }}/>
                      <Text style={styles.txtLoading}>Loading forms, please wait.</Text>
                    </View>
                }
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >

          </View>
        </View>
        <View style={{ flex: 1 }} />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.get("authToken"),
    previousSubmissions: state.submissions.get("previousSubmissions"),
    formList: state.tasks.get("formList"),
    showTypesFilter: state.tasks.get("showTypesFilter"),
    taskCreated: state.tasks.get("taskCreated"),
    formsLoaded: state.tasks.get("formsLoaded"),
    formsLoadedEnd: state.tasks.get("formsLoadedEnd"),
  };
};

export default connect(mapStateToProps, (dispatch, props) => {
  return {
    getTasks: () => {
      dispatch(getTasks());
    },
    setFilterSelected: () => {
      dispatch(setFilterSelected());
    },
    setFormSelected: () => {
      dispatch(setFormSelected());
    },
    setTaskSelected: () => {
      dispatch(setTaskSelected());
    },
    shouldReloadTasks: (value) => {
      dispatch(shouldReloadTasks(value));
    },
    setFormsFromAPI: () => {
      dispatch(setFormsFromAPI());
    },
    createNewTask: (task) => {     
      return new Promise((resolve, reject) => {
        dispatch(setIsSubmission(false));
        dispatch(setSubmissionSelected(null));
        dispatch(createNewTask(task));
        resolve();
      });    
    },
    setPromptTitle: (val) => {
      dispatch(setPromptTitle(val));
    },
    selectForm: (id, name, previousSubmissions, task_id, promptMessages, isPallet, isReceivingLog, isOrder, isRecipe, defaultYield, isInvoice, isShipmentLog, isReconciliationLog, isInventoryTransfer) => {
      // if (!previousSubmissions[task_id]) {
        props.navigation.navigate('FormView', { formId: id, title: name, newReport:true, submission:false , task_id: task_id, log_group_id:null, promptMessages, isPallet, isReceivingLog, isOrder, isRecipe, defaultYield, isInvoice, isShipmentLog, isReconciliationLog, isInventoryTransfer });    
        return;
      // }

      // Swal.fire({
      //   title: "Open Report",
      //   text: "Would you like to start a new report, or reopen the previous report?",
      //   showCancelButton: true,
      //   showDenyButton: true,
      //   cancelButtonText: "Cancel",
      //   confirmButtonText: "New Report",
      //   denyButtonText: "Reopen Previous Report",
      //   confirmButtonColor: colors.orange,
      //   backdrop: false

      // }).then((result) => {
      //   if(result.isConfirmed) {
      //     dispatch(navigateForm(id, name, true, task_id));
      //   } else if (result.isDenied) {
      //     dispatch(navigateForm(id, name, false, task_id));
      //   }
      // });
    },
  };
})(NewTask);

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    borderRadius: 100,
    height: 60,
    justifyContent: "center",
    marginBottom: 10,
    marginRight: 20,
    marginTop: 10,
    minWidth: 160,
    overflow: "hidden",
    padding: 10,
  },
  buttonCreate: {
    height: 70,
    width: Dimensions.get("window").width,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
    shadowColor: "black",
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowRadius: 6,
  },
  buttonCreateText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonText: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  container: {
    backgroundColor: "#F8F8F8",
    flex: 1,
    justifyContent: "space-between",
  },
  input: {
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 12,
    color: colors.gray_darken_2,
    fontFamily: "Roboto",
    fontSize: 18,
    height: 65,
    marginTop: 15,
    marginBottom: 15,
    paddingBottom: 0,
    paddingLeft: 20,
  },
  inputSearch: {
    flex: 1,
    height: 70,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontSize: 18,
    backgroundColor: "#fff",
    color: "#90A4AE",
  },
  labelInput: {
    color: colors.blue_gray,
    fontSize: 18,
    paddingLeft: 10,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: "Roboto",
  },
  title: {
    paddingLeft: 10,
    fontSize: 22,
    fontFamily: "Roboto-Medium",
    marginTop: 20,
    marginBottom: 10,
  },
  searchSection: {
    height: 70,
    flexDirection: "row",
    backgroundColor: "#fff",
    alignItems: "center",
    marginBottom: 10,
  },
  searchContainer: {
    flex: 1,
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchIcon: {
    paddingLeft: 20,
  },
  subtitle: {
    color: colors.black,
    fontSize: 16,
    paddingLeft: 10,
    paddingBottom: 10,
    marginTop: 10,
    fontFamily: "Roboto-Italic",
  },
  whiteSection: {
    backgroundColor: "#fff",
    marginTop: 20,
    padding: 10,
  },
  viewContainerMax: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  refreshIcon: {
    fontSize: 15,
    paddingHorizontal: 10,
    color: "gray",
  },
  txtLoading: { 
    fontSize: 16, 
    color: colors.blue_gray, 
    margin: 10, 
    fontWeight: 600 
  },
  btnFilter: {
    width: '50%', 
    textAlign: 'center', 
    borderRadius: 5, 
    padding: 5 
  }
});
